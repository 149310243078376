<template>
  <div class="drawableInfo">
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="$t.noMoreData"
      @load="moreOnLoad"
    >
      <drawable-info-title
        :company="company"
        :customerId="customerId"
        @reloadMethod="loadCustomerInfoAndProductByPage"
      ></drawable-info-title>
      <drawable-info-list :productList="productList"></drawable-info-list>
    </van-list>
  </div>
</template>
<script>
import DrawableInfoTitle from "./components/DrawableInfoTop";
import DrawableInfoList from "./components/DrawableInfoList";
import Vue from "vue";
import { List } from "vant";
Vue.use(List);
export default {
  name: "DrawableInfo",
  components: {
    DrawableInfoTitle,
    DrawableInfoList
  },
  data() {
    return {
      productList: [],
      pageIndex: 1, //页码
      customerId: "",
      company: {}, //供应商信息
      finished: false,
      loading: true
      //isNoData: false,
      //isfirst: true
    };
  },
  beforeMount() {
    const { CustomerId } = this.$route.query;
    this.customerId = CustomerId;
    //console.log(this.customerId);
    //console.log(this.$route.query);
  },
  mounted() {
    //首次加载供应商信息
    this.loadCustomerInfoAndProductByPage();
  },
  methods: {
    // 供应商信息
    loadCustomerInfoAndProductByPage() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      let param = {
        PageIndex: this.pageIndex,
        CustomerId: this.customerId
      };
      this.$api.follow
        .loadCustomerInfoAndProductByPage(param)
        .then(res => {
          const data = res.data;
          this.company = data;
          this.productList = data.ProductList;
          this.loading = false;
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
        })
        .catch(error => {
          this.isfirst = false;
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status
          });
        });
    },
    //加载更多
    moreOnLoad() {
      let param = {
        PageIndex: this.pageIndex + 1,
        CustomerId: this.customerId
      };
      this.$api.follow
        .loadCustomerInfoAndProductByPage(param)
        .then(res => {
          const data = res.data;
          this.company = data;
          if (data.ProductList !== null) {
            this.loading = false;
            this.pageIndex = this.pageIndex + 1;
            this.productList = this.productList.concat(data.ProductList);
          } else if (data.ProductList === null) {
            this.loading = false;
            this.finished = true;
            //this.isNoData = true;
          }
        })
        .catch(error => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status
          });
        });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";

.drawableInfo {
  font-size: 28px;
  width: 100%;
}
</style>
