<template>
  <div class="drawableInfoTitle">
    <!-- <figure class="back">
      <img
        class="backIcon"
        src="~assets/img/drawableinfo/icon_shop_right.png"
      />
    </figure>-->
    <van-row type="flex" align="center" class="userBox">
      <van-col :span="4" class="userImg">
        <figure class="logo">
          <van-image
            class="logoIcon"
            :src="company.HeadPhoto && imgUrlFilter(company.HeadPhoto)"
          >
            <template v-slot:error>
              <img
                class="ImgIcon"
                src="~assets/img/userManage/user_logo@2x.png"
              />
            </template>
          </van-image>
        </figure>
      </van-col>
      <van-col :span="17" class="userName">{{ company.CompanyName }}</van-col>
    </van-row>
    <van-row type="flex" align="center" class="drawableModel">
      <van-col :span="3" class="drawableModelLeft">
        <img
          class="drawableModelLeftIcon"
          v-if="company.IsFollow === 1"
          src="~assets/img/drawableinfo/icon_shop_crown_select.png"
        />
        <img
          class="drawableModelLeftIcon"
          v-else
          src="~assets/img/drawableinfo/icon_shop_crown.png"
        />
      </van-col>
      <van-col :span="14" class="drawableModelTitle">
        {{ company.IsFollow === 1 ? $t.belongToSupplier : $t.notAreToSupplier }}
      </van-col>
      <van-col :span="7" class="attention">
        <button
          class="attentionBt"
          v-if="company.IsFollow !== 1"
          @click="editFollowCustomer(1)"
        >
          {{ $t.attention }}
        </button>

        <button
          class="hasAttentionBt"
          v-if="company.IsFollow == 1"
          @click="editFollowCustomer(0)"
        >
          {{ $t.hasAttention }}
        </button>
      </van-col>
    </van-row>
  </div>
</template>
<script>
import Vue from "vue";
import { Row, Col } from "vant";
Vue.use(Row).use(Col);

export default {
  name: "DrawableInfoTitle",
  props: ["company", "customerId"],
  data() {
    return {
      isattention: true,
    };
  },
  methods: {
    imgUrlFilter(value) {
      return this.$api.formatImageUrl(value);
    },
    // 供应商信息
    editFollowCustomer(IsFollow = 1) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      let param = {
        IsFollow: IsFollow,
        CustomerId: this.customerId,
      };
      this.$api.follow
        .editFollowCustomer(param)
        .then((res) => {
          const data = res.data;
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonMethod.customAlert(
            {
              message: res.message,
              confirmButtonText: this.$t.okText,
              showCancelButton: false,
            },
            () => {},
            () => {}
          );
          this.$emit("reloadMethod");
        })
        .catch((error) => {
          this.isfirst = false;
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin spanNowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin puiblicFlex {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
}
.drawableInfoTitle {
  width: 100%;
  position: relative;
  padding: 8% 0;
  // background-image: linear-gradient(to bottom right, #61cc89, #29b899);
  // background-image: -webkit-linear-gradient(to bottom right, #61cc89, #29b899);
  background: linear-gradient(left, #71d283, #01aaa3);
  .back {
    margin: 0;
    padding: 0;
    margin-left: 4%;
    width: 33px;
    @include puiblicFlex;
    .backIcon {
      width: 100%;
      margin: 0;
      padding: 0;
    }
  }
  .userBox {
    width: 92%;
    margin: 0 auto;
    margin-bottom: 0.4rem;
    .userImg {
      width: 22%;
      text-align: center;
      .logo {
        margin: 0;
        .logoIcon {
          .ImgIcon {
            width: 55px;
            height: 55px;
            object-fit: cover;
            border-radius: 100%;
          }
          ::v-deep .van-image__img {
            width: 55px;
            height: 55px;
            position: relative;
            border-radius: 100%;
          }
          ::v-deep .van-image__error {
            width: 55px;
            height: 55px;
            position: relative;
            border-radius: 100%;
          }
        }
      }
    }
    .userName {
      color: #ffffff;
      font-size: 18px;
      width: 70.8%;
    }
  }
  .drawableModel {
    background-color: #ffffff;
    width: 92%;
    margin: 0 auto;
    border-radius: 20px;
    padding: 10px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    .drawableModelLeft {
      @include puiblicFlex;
      text-align: left;
      width: 10%;
      justify-content: flex-start;
      .drawableModelLeftIcon {
        width: 100%;
        width: 25px;
      }
    }
    .drawableModelTitle {
      color: #999999;
      font-size: 14px;
      width: 58.84%;
    }
    .attention {
      width: 29.16%;
      line-height: 30px;
      .attentionBt {
        width: 100%;
        border: 1px solid #43cd9a;
        border-radius: 40px;
        font-size: 14px;
        background-color: #ffffff;
        color: #43cd9a;
        padding: 0;
      }
      .hasAttentionBt {
        width: 100%;
        border: 1px solid transparent;
        border-radius: 40px;
        font-size: 14px;
        background-color: #43cd9a;
        color: #ffffff;
        padding: 0;
      }
    }
  }
}
</style>
